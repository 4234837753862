import {
  TAXONOMY_RESOURCE_PATH,
  TAXONOMY_SUBTYPE_PATH,
  ISectionedMenuProps
} from '@logicmanager/common';

export enum APPLICATION_CONSTANTS {
  TAXONOMY = 'taxonomy',
  LIBRARY = 'library',
  REASON_FOR_LOGIN = 'reason-for-login',
  REPORT_PORTAL = 'report-portal',
  DATA_LOAD_UTILITY = 'data-load-utility',
  PLANS = 'plans'
}

export enum DATA_TEST_ID {
  HEADER_TAXONOMY_DROPDOWN = 'header-taxonomy-dropdown',
  HEADER_TAXONOMY_DROPDOWN_BP = 'header-taxonomy-option-business-processes',
  HEADER_LIBRARY_DROPDOWN = 'header-library-dropdown',
  HEADER_CONFIGURATION_SECTION = 'header-library-section'
}

export enum HEADER_LINKS {
  HOME = '',
  LIBRARY = 'library',
  PLANS = 'plans',
  TAXONOMY = 'taxonomy',
  CONFIGURATION = 'configuration',
  INCIDENTS = 'incident-management',
  POLICY_PORTAL = 'policies',
  INITIATIVES = 'programs'
}

export enum LIBRARY_RESOURCE_PATH {
  PERFORMANCE = 'performance',
  READINESS = 'readiness',
  RISK = 'risk',
  CONTROLS = 'controls'
}

export enum TAXONOMY_MENU_SECTION_NAME {
  BUSINESS_PROCESS = 'Business Processes',
  RESOURCE = 'Resources',
  AUDIT = 'Audit'
}

export enum TAXONOMY_PERMISSIONS {
  PROCESS = 'PROCESSES',
  RELATIONSHIP = 'VENDORS',
  PHYSICAL_ASSET = 'PHYSICAL_ASSETS',
  APPLICATION = 'APPLICATIONS',
  AUDIT = 'AUDITS',
  POLICY = 'POLICIES',
  PEOPLE = 'EMPLOYEES',
  DATA = 'DATA',
  FINANCIAL = 'FINANCIAL_ELEMENTS'
}

export const TAXONOMY_MANAGE_AUTO_RULES_PERMISSIONS: {[TAXONOMY_PERMISSIONS: string]: string} = {
  [TAXONOMY_PERMISSIONS.PROCESS]: 'MANAGE_BUSINESS_PROCESSES_AUTOMATION_RULES',
  [TAXONOMY_PERMISSIONS.RELATIONSHIP]: 'MANAGE_VENDOR_AUTOMATION_RULES',
  [TAXONOMY_PERMISSIONS.PHYSICAL_ASSET]: 'MANAGE_PHA_AUTOMATION_RULES',
  [TAXONOMY_PERMISSIONS.APPLICATION]: 'MANAGE_APP_AUTOMATION_RULES',
  [TAXONOMY_PERMISSIONS.AUDIT]: 'MANAGE_AUDIT_AUTOMATION_RULES',
  [TAXONOMY_PERMISSIONS.POLICY]: 'MANAGE_POLICY_AUTOMATION_RULES',
  [TAXONOMY_PERMISSIONS.PEOPLE]: 'MANAGE_EMPLOYEE_AUTOMATION_RULES',
  [TAXONOMY_PERMISSIONS.DATA]: 'MANAGE_DATA_AUTOMATION_RULES'
};

export enum TAXONOMY_MENU_LINK_TEXT {
  CORE_PROCESS = 'Core Processes',
  OTHER_DIMENSION = 'Other Dimensions',
  APPLICATION = 'Applications',
  AUDIT = 'Audits',
  DATA = 'Data',
  PEOPLE = 'People',
  PHYSICAL_ASSET = 'Physical Assets',
  POLICY = 'Policies',
  RELATIONSHIP = 'Relationships',
  FINANCIAL = 'Financials'
}

export const TAXONOMY_MENU_DEFAULT_SECTIONS: ISectionedMenuProps = {
  menuTitle: 'Taxonomy',
  menuSections: {
    [TAXONOMY_MENU_SECTION_NAME.BUSINESS_PROCESS]: {
      name: TAXONOMY_MENU_SECTION_NAME.BUSINESS_PROCESS,
      links: [
        {
          name: TAXONOMY_MENU_LINK_TEXT.CORE_PROCESS,
          url: `/${HEADER_LINKS.TAXONOMY}/${TAXONOMY_RESOURCE_PATH.BUSINESS_PROCESS}/${TAXONOMY_SUBTYPE_PATH.CORE_PROCESS}`
        },
        {
          name: TAXONOMY_MENU_LINK_TEXT.OTHER_DIMENSION,
          url: `/${HEADER_LINKS.TAXONOMY}/${TAXONOMY_RESOURCE_PATH.BUSINESS_PROCESS}/${TAXONOMY_SUBTYPE_PATH.OTHER_DIMENSION}`
        }
      ],
      sequence: 0,
    },
    [TAXONOMY_MENU_SECTION_NAME.RESOURCE]: {
      name: TAXONOMY_MENU_SECTION_NAME.RESOURCE,
      links: [
        {
          name: TAXONOMY_MENU_LINK_TEXT.APPLICATION,
          url: `/${HEADER_LINKS.TAXONOMY}/${TAXONOMY_RESOURCE_PATH.APPLICATION}`
        },
        {
          name: TAXONOMY_MENU_LINK_TEXT.DATA,
          url: `/${HEADER_LINKS.TAXONOMY}/${TAXONOMY_RESOURCE_PATH.DATA}`
        },
        {
          name: TAXONOMY_MENU_LINK_TEXT.PEOPLE,
          url: `/${HEADER_LINKS.TAXONOMY}/${TAXONOMY_RESOURCE_PATH.PEOPLE}`
        },
        {
          name: TAXONOMY_MENU_LINK_TEXT.PHYSICAL_ASSET,
          url: `/${HEADER_LINKS.TAXONOMY}/${TAXONOMY_RESOURCE_PATH.PHYSICAL_ASSET}`
        },
        {
          name: TAXONOMY_MENU_LINK_TEXT.POLICY,
          url: `/${HEADER_LINKS.TAXONOMY}/${TAXONOMY_RESOURCE_PATH.POLICY}`
        },
        {
          name: TAXONOMY_MENU_LINK_TEXT.RELATIONSHIP,
          url: `/${HEADER_LINKS.TAXONOMY}/${TAXONOMY_RESOURCE_PATH.RELATIONSHIP}`
        }
      ],
      sequence: 1,
    },
    [TAXONOMY_MENU_SECTION_NAME.AUDIT]: {
      name: TAXONOMY_MENU_SECTION_NAME.AUDIT,
      links: [
        {
          name: TAXONOMY_MENU_LINK_TEXT.AUDIT,
          url: `/${HEADER_LINKS.TAXONOMY}/${TAXONOMY_RESOURCE_PATH.AUDIT}`
        },
        {
          name: TAXONOMY_MENU_LINK_TEXT.FINANCIAL,
          url: `/${HEADER_LINKS.TAXONOMY}/${TAXONOMY_RESOURCE_PATH.FINANCIAL}`
        },
      ],
      sequence: 1,
    },
  }
};

export const PERMISSIONS_TO_LINK_NAMES_MAPPING: {[PERMISSION: string ]: string[]} = {
  [TAXONOMY_PERMISSIONS.PROCESS]: [TAXONOMY_MENU_LINK_TEXT.CORE_PROCESS, TAXONOMY_MENU_LINK_TEXT.OTHER_DIMENSION],
  [TAXONOMY_PERMISSIONS.RELATIONSHIP]: [TAXONOMY_MENU_LINK_TEXT.RELATIONSHIP],
  [TAXONOMY_PERMISSIONS.PHYSICAL_ASSET]: [TAXONOMY_MENU_LINK_TEXT.PHYSICAL_ASSET],
  [TAXONOMY_PERMISSIONS.APPLICATION]: [TAXONOMY_MENU_LINK_TEXT.APPLICATION],
  [TAXONOMY_PERMISSIONS.AUDIT]: [TAXONOMY_MENU_LINK_TEXT.AUDIT],
  [TAXONOMY_PERMISSIONS.POLICY]: [TAXONOMY_MENU_LINK_TEXT.POLICY],
  [TAXONOMY_PERMISSIONS.PEOPLE]: [TAXONOMY_MENU_LINK_TEXT.PEOPLE],
  [TAXONOMY_PERMISSIONS.DATA]: [TAXONOMY_MENU_LINK_TEXT.DATA],
  [TAXONOMY_PERMISSIONS.FINANCIAL]: [TAXONOMY_MENU_LINK_TEXT.FINANCIAL],
};

export const DEFAULT_BUSINESS_PROCESS_ROUTE: string =
  `${TAXONOMY_RESOURCE_PATH.BUSINESS_PROCESS}/${TAXONOMY_SUBTYPE_PATH.CORE_PROCESS}`;

export const DEFAULT_TAXONOMY_ROUTE: string = DEFAULT_BUSINESS_PROCESS_ROUTE;

// the Valid routes below are necessary to set the taxonomy types on the match props
export const VALID_LIBRARY_ROUTES: string[] = [
  `/:taxonomyType(${HEADER_LINKS.LIBRARY})/:resource(${LIBRARY_RESOURCE_PATH.PERFORMANCE})/:elementId?`,
  `/:taxonomyType(${HEADER_LINKS.LIBRARY})/:resource(${LIBRARY_RESOURCE_PATH.READINESS})/:elementId?`,
  `/:taxonomyType(${HEADER_LINKS.LIBRARY})/:resource(${LIBRARY_RESOURCE_PATH.RISK})/:elementId?`,
  `/:taxonomyType(${HEADER_LINKS.LIBRARY})/:resource(${LIBRARY_RESOURCE_PATH.CONTROLS})/:elementId?`
];

export const VALID_TAXONOMY_ROUTES: string[] = [
  `/:taxonomyType(${HEADER_LINKS.TAXONOMY})/:resource(${TAXONOMY_RESOURCE_PATH.BUSINESS_PROCESS})/:subtype(${TAXONOMY_SUBTYPE_PATH.CORE_PROCESS}|${TAXONOMY_SUBTYPE_PATH.OTHER_DIMENSION})/:elementId?`,
  `/:taxonomyType(${HEADER_LINKS.TAXONOMY})/:resource(${TAXONOMY_RESOURCE_PATH.RELATIONSHIP})/:elementId?`,
  `/:taxonomyType(${HEADER_LINKS.TAXONOMY})/:resource(${TAXONOMY_RESOURCE_PATH.PHYSICAL_ASSET})/:elementId?`,
  `/:taxonomyType(${HEADER_LINKS.TAXONOMY})/:resource(${TAXONOMY_RESOURCE_PATH.APPLICATION})/:elementId?`,
  `/:taxonomyType(${HEADER_LINKS.TAXONOMY})/:resource(${TAXONOMY_RESOURCE_PATH.AUDIT})/:elementId?`,
  `/:taxonomyType(${HEADER_LINKS.TAXONOMY})/:resource(${TAXONOMY_RESOURCE_PATH.POLICY})/:elementId?`,
  `/:taxonomyType(${HEADER_LINKS.TAXONOMY})/:resource(${TAXONOMY_RESOURCE_PATH.PEOPLE})/:elementId?`,
  `/:taxonomyType(${HEADER_LINKS.TAXONOMY})/:resource(${TAXONOMY_RESOURCE_PATH.DATA})/:elementId?`,
  `/:taxonomyType(${HEADER_LINKS.TAXONOMY})/:resource(${TAXONOMY_RESOURCE_PATH.FINANCIAL})/:elementId?`
];

export const VALID_POLICY_PORTAL_ROUTE: string = `/:taxonomyType(${HEADER_LINKS.POLICY_PORTAL})/:elementId?`;

export const VALID_INITIATIVES_ROUTE: string = `/:taxonomyType(${HEADER_LINKS.INITIATIVES})/:elementId?`;

export const SOLUTION_CENTER_URL: string = 'https://www.logicmanager.com/solutions/';

export enum LOGIC_MANAGER_UNIVERSITY_LINK {
  NAME = 'LogicManager University',
  URL = 'https://support.logicmanager.com/hc/en-us'
}

export enum PROVIDE_FEEDBACK_LINK {
  NAME = 'Provide Feedback',
  URL = 'https://internal.logicmanager.com/incidents/?t=275&p=37&k=a532400ed62e772b9dc0b86f46e583ff'
}
