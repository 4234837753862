import * as React from 'react';
import { autobind } from 'core-decorators';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import {
  APP_AREA,
  APP_AREA_TO_URL_PATHNAME,
  FEATURE_FLAGS,
  FileApi,
  IMenuLink,
  ISectionedMenuProps,
  LMExpertChatComponent,
  SectionedMenu,
  LocalStorage
} from '@logicmanager/common';
import * as BUILD from '../../../config/config';
import './header.less';
import {
  APPLICATION_CONSTANTS,
  DATA_TEST_ID,
  HEADER_LINKS,
  LOGIC_MANAGER_UNIVERSITY_LINK,
  PROVIDE_FEEDBACK_LINK,
  SOLUTION_CENTER_URL,
  TAXONOMY_MENU_DEFAULT_SECTIONS
} from '../../constants/constants';
import { getAccessPermissions } from '../../functions';
import { ExternalAppUtils } from '../../functions/external-app-utilities';

export class AppHeader extends React.Component<IHeaderProps, IHeaderState> {


  public state: IHeaderState = {
    showPlans: false,
    showTaxonomy: [],
    showLibrary: [],
    showReportPortal: false,
    showPolicyPortal: false,
    showInitiatives: true, // adjust once permissions are applied
    showConfiguration: false,
    showIncidentManagement: false,
    useCustomLogo: false,
    customUrl: '',
    isLoading: true,
    viewControlLibrary: false
  };

  public async componentDidMount(): Promise<void> {
    const initiativesEnabled: boolean = !!LocalStorage.getFeatureFlag(FEATURE_FLAGS.TASKS_AND_INITIATIVES);

    const permissions: {[permissionType: string]: boolean | string[]} = await getAccessPermissions();
    let logoUrl: string = '';
    if (permissions.useHeaderLogo) {
      await FileApi.getHeaderLogo().then((data: Blob) => {
        logoUrl = URL.createObjectURL(data);
      });
    }
    this.setState({
      showPlans: permissions.canAccessPlans as boolean,
      showTaxonomy: permissions.canAccessTaxonomy as string[],
      showLibrary: permissions.canAccessLibrary as string[],
      showReportPortal: permissions.canAccessReportPortal as boolean,
      showPolicyPortal: permissions.policyPortalEnabled as boolean,
      showConfiguration: permissions.canAccessConfiguration as boolean,
      showIncidentManagement: permissions.canAccessIncidentsManagement as boolean,
      showInitiatives: initiativesEnabled as boolean && permissions.canAccessPrograms as boolean,
      useCustomLogo: permissions.useHeaderLogo as boolean,
      viewControlLibrary: permissions.viewControlLibrary as boolean,
      customUrl: logoUrl,
      isLoading: false
    });
  }

  public componentWillUnmount(): void {
    URL.revokeObjectURL(this.state.customUrl);
  }

  @autobind
  public getAppArea(): APP_AREA {
    const pathname: string[] = this.props.pathname && this.props.pathname.split('/');
    const appFromUrl: string = !!pathname && pathname.length > 0 ? pathname[1] : null;
    switch (appFromUrl) {
      case APP_AREA_TO_URL_PATHNAME[APP_AREA.TAXONOMY]:
        return APP_AREA.TAXONOMY;
      case APP_AREA_TO_URL_PATHNAME[APP_AREA.PLANS]:
        return APP_AREA.PLANS;
      case APP_AREA_TO_URL_PATHNAME[APP_AREA.LIBRARY]:
        return APP_AREA.LIBRARY;
      case APP_AREA_TO_URL_PATHNAME[APP_AREA.INCIDENTS]:
      case APP_AREA_TO_URL_PATHNAME[APP_AREA.INCIDENT_MANAGEMENT]:
        return APP_AREA.INCIDENTS;
      case APP_AREA_TO_URL_PATHNAME[APP_AREA.POLICY_PORTAL]:
        return APP_AREA.POLICY_PORTAL;
      case APP_AREA_TO_URL_PATHNAME[APP_AREA.INITIATIVES]:
        return APP_AREA.INITIATIVES;
      default:
        return null;
    }
  }

  @autobind
  private getAreaClassName(appArea: APP_AREA): string {
    return `underline${this.getAppArea() === appArea ? ' current-area' : ''}`;
  }

  @autobind
  private getUserDropdown(): JSX.Element {
    return (
      <Menu.Menu position="right">
        {this.getLMXChat()}
        {this.getChatSupport()}
        {this.getConfiguration()}
        <Dropdown
          item
          text={this.getUserNameInitials()}
          className="header-dropdown"
          icon={null}
        >
          <Dropdown.Menu>
            <Dropdown.Item>
              <a href={LOGIC_MANAGER_UNIVERSITY_LINK.URL} target="_blank">
                {LOGIC_MANAGER_UNIVERSITY_LINK.NAME}
              </a>
            </Dropdown.Item>
            <Dropdown.Item>
              <a href={PROVIDE_FEEDBACK_LINK.URL} target="_blank">
                {PROVIDE_FEEDBACK_LINK.NAME}
              </a>
            </Dropdown.Item>
            <Dropdown.Item
              key="logout-item"
              onClick={this.props.onLogout}
              text={HEADER_TEXT.LOGOUT}
              selected={false}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    );
  }

  private getUserNameInitials(): string {
    if (!this.props.lastName) {
      const splitFirstName: string[] = this.props.firstName ? this.props.firstName.trim().split(' ') : [];
      switch (splitFirstName.length) {
        case 0:
          return ' ';
        case 1:
          return splitFirstName[0][0].toUpperCase();
        default:
          return splitFirstName[0][0].toUpperCase() + splitFirstName[splitFirstName.length - 1][0].toUpperCase();
      }
    }
    return this.props.firstName[0].toUpperCase() + this.props.lastName[0].toUpperCase();
  }

  @autobind
  private getChatSupport(): JSX.Element {
    return (
      <Menu.Item className="chat-support-item">
        <a
          href={BUILD.CHAT_SUPPORT_URL}
          className="chat-support"
          target={'_blank'}
        >
          {HEADER_TEXT.CHAT_SUPPORT}
        </a>
      </Menu.Item>
    );
  }

  @autobind
  private getConfiguration(): JSX.Element {

    if (this.state.showConfiguration) {
      return (
        <Menu.Item className="configuration-item">
          <NavLink
            to={`/${HEADER_LINKS.CONFIGURATION}`}
            data-test-id={DATA_TEST_ID.HEADER_CONFIGURATION_SECTION}
            activeClassName="configuration-active"
            className="configuration-inactive"
          >
            <Icon name="cog" size="large" />
          </NavLink>
        </Menu.Item>
      );
    }
    return null;
  }

  @autobind
  private getTaxonomyPermissionedMenuData(
    taxonomyLinkNames: string[], fullTaxonomyMenu: ISectionedMenuProps
  ): ISectionedMenuProps {
    const newTaxonomyMenu: ISectionedMenuProps = {...fullTaxonomyMenu};
    for (const sectionName of Object.keys(newTaxonomyMenu.menuSections)) {
      newTaxonomyMenu.menuSections[sectionName].links =
        newTaxonomyMenu.menuSections[sectionName].links.filter(
          (linkInfo: IMenuLink): boolean => taxonomyLinkNames.includes(linkInfo.name));
    }
    return newTaxonomyMenu;
  }

  @autobind
  private getLibraryDropdown(libraryLinkNames: string[]): JSX.Element {
    const { viewControlLibrary } = this.state;
    if (libraryLinkNames.length === 0 && !viewControlLibrary) {
      return null;
    }
    const libraryLinks: IMenuLink[] = [];
    libraryLinkNames.map((name: string) => {
      libraryLinks.push({
        name: name.charAt(0).toUpperCase() + name.slice(1),
        url: `/library/${name.toLowerCase()}`,
      });
    });
    const controlLibraryLink: IMenuLink[] = viewControlLibrary
      ? [{
        name: 'Controls',
        url: '/library/controls'
      }]
      : [];
    const libraryMenuData: ISectionedMenuProps = {
      menuTitle: 'Library',
      menuSections: {
        library: {
          name: 'Indicators',
          links: libraryLinks,
          sequence: 0,
        },
        controlLibrary: {
          name: 'Mitigation',
          links: controlLibraryLink,
          sequence: 1
        }
      },
      singleColumn: false
    };
    return (
      <Menu.Menu>
        <Menu.Item className={this.getAreaClassName(APP_AREA.LIBRARY)}>
          <SectionedMenu
            {...libraryMenuData}
          />
        </Menu.Item>
      </Menu.Menu>
    );
  }

  @autobind
  private getTaxonomyDropdown(): JSX.Element {
    if (!BUILD.EXCLUSIONS.includes(APPLICATION_CONSTANTS.TAXONOMY) && this.state.showTaxonomy.length) {
      return (
        <Menu.Menu>
          <Menu.Item className={this.getAreaClassName(APP_AREA.TAXONOMY)}>
            <SectionedMenu
              {...this.getTaxonomyPermissionedMenuData(this.state.showTaxonomy, TAXONOMY_MENU_DEFAULT_SECTIONS)}
            />
          </Menu.Item>
        </Menu.Menu>
      );
    }
    return null;
  }

  @autobind
  private getSolutionCenter(): JSX.Element {
    return (
      <Menu.Item className="underline">
        <a href={SOLUTION_CENTER_URL} target="_blank">
          {HEADER_TEXT.SOLUTION_CENTER}
        </a>
      </Menu.Item>
    );
  }

  @autobind
  private getPortals(): JSX.Element {
    const portalLinks: IMenuLink[] = [];
    if (!BUILD.EXCLUSIONS.includes(APPLICATION_CONSTANTS.REPORT_PORTAL) && this.state.showReportPortal) {
      const openReportPortal: () => void = () => ExternalAppUtils.openReportPortal();
      portalLinks.push(
        {
          name: 'Report Portal',
          url: 'reports',
          onClick: openReportPortal
        }
      );
    }
    if (this.state.showPolicyPortal) {
      portalLinks.push(
        {
          name: 'Policy Portal',
          url: '/policies'
        }
      );
    }
    if (portalLinks.length === 0) {
      return null;
    }
    const portalsMenuData: ISectionedMenuProps = {
      menuTitle: 'Portals',
      menuSections: {
        library: {
          links: portalLinks,
          sequence: 0,
        }
      },
      singleColumn: true
    };
    return (
      <Menu.Menu>
        <Menu.Item className={this.getAreaClassName(APP_AREA.POLICY_PORTAL)}>
          <SectionedMenu
            {...portalsMenuData}
          />
        </Menu.Item>
      </Menu.Menu>
    );
  }

  @autobind
  private getPlans(): JSX.Element {
    if (this.state.showPlans) {
      return (
        <Menu.Item className={this.getAreaClassName(APP_AREA.PLANS)}>
          <Link to={`/${HEADER_LINKS.PLANS}`}>
            {HEADER_TEXT.PLANS}
          </Link>
        </Menu.Item>
      );
    }
    return null;
  }

  @autobind
  private getLogo(): JSX.Element {
    if (this.state.useCustomLogo) {
      return (
        <Menu.Item>
          <Link to={`/${HEADER_LINKS.HOME}`}>
            <div className="custom-logo">
              <img src="/static/LogicManagerLogoTriangleWhite.png" />
              <div className="vertical-line" />
              <img src={this.state.customUrl} />
            </div>
          </Link>
        </Menu.Item>
      );
    }
    return (
      <Menu.Item>
        <Link to={`/${HEADER_LINKS.HOME}`}>
          <img src="/static/LogicManagerLogoWhite.png" />
        </Link>
      </Menu.Item>
    );
  }

  @autobind
  private getIncidentsManagement(): JSX.Element {
    if (this.state.showIncidentManagement) {
      return (
        <Menu.Item className={this.getAreaClassName(APP_AREA.INCIDENTS)}>
          <Link to={`/${HEADER_LINKS.INCIDENTS}`}>
            {HEADER_TEXT.INCIDENTS}
          </Link>
        </Menu.Item>
      );
    }
    return null;
  }

  @autobind
  private getLMXChat(): JSX.Element {
    return <LMExpertChatComponent />;
  }

  @autobind
  private getInitiatives(): JSX.Element {
    if (this.state.showInitiatives) {
      return (
        <Menu.Item className={this.getAreaClassName(APP_AREA.INITIATIVES)} data-test-id="programs-header">
          <Link to={`/${HEADER_LINKS.INITIATIVES}`}>
            {HEADER_TEXT.INITIATIVES}
          </Link>
        </Menu.Item>
      );
    }
    return null;
  }

  private getHeaderMenuItems(): JSX.Element[] {
    if (this.state.isLoading) {
      return [this.getUserDropdown()];
    }
    return [
      this.getLogo(),
      this.getTaxonomyDropdown(),
      this.getPlans(),
      this.getInitiatives(),
      this.getLibraryDropdown(this.state.showLibrary),
      this.getIncidentsManagement(),
      this.getPortals(),
      this.getSolutionCenter(),
      this.getUserDropdown()
    ];
  }

  public render(): JSX.Element {
    return (
      <Menu borderless className="lm-nav-menu">
        {this.getHeaderMenuItems()}
      </Menu>
    );
  }
}

interface IHeaderProps {
  firstName: string;
  lastName: string;
  pathname: string;
  onLogout(): void;
}

interface IHeaderState {
  showTaxonomy: string[];
  showLibrary: string[];
  showReportPortal: boolean;
  showPolicyPortal: boolean;
  readonly showInitiatives: boolean;
  showPlans: boolean;
  showConfiguration: boolean;
  showIncidentManagement: boolean;
  useCustomLogo: boolean;
  customUrl: string;
  isLoading: boolean;
  readonly viewControlLibrary: boolean;
}

enum HEADER_TEXT {
  LOGOUT = 'Log out',
  TAXONOMY = 'Taxonomy',
  LIBRARY = 'Library',
  INCIDENTS = 'Events',
  REPORT_PORTAL = 'Report Portal',
  PLANS = 'Plans',
  CONFIGURATION = 'Configuration',
  CHAT_SUPPORT = 'Chat with support',
  SOLUTION_CENTER = 'Solution Center',
  INITIATIVES = 'Programs'
}
